html {
  font-size: 18px;
  overflow: hidden;
  font-family: "Proxima Nova", Arial, sans-serif;
}
body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
  overflow-x: hidden !important;
  line-height: 1.5;
  letter-spacing: 0.3px;
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Proxima Nova", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

textarea,
input,
h1,
h2,
h3,
h4,
h5,
h6,
.header .header-container ul.navigation li a,
.topbar-btn,
button {
  /* font-family: Arial Rounded MT Bold, Helvetica Rounded, Arial, sans-serif !important; */
  font-family: "Proxima Nova", Arial, sans-serif;
}

h2,
h3,
h4,
h5,
h6 {
  color: black !important;
}
.header .header-container ul.navigation li a,
.topbar-btn,
button {
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: white !important;
}
.header .header-container ul.navigation li a:hover {
  background: #004ca3 !important;
}
.landing p {
  color: #1a1a1a;
}
section.header {
  background: #011c3a !important;
}
@media (min-width: 1201px) {
  section#intro6 > div {
    padding: 12rem !important;
    background-position: center !important;
  }
}
@media (max-width: 1200px) {
  section#intro6 > div {
    padding: 14rem !important;
    background-size: cover !important;
  }
}
@media (max-width: 766px) {
  section#intro6 > div {
    padding: 7rem 6rem 7rem 6rem !important;
    background-size: cover !important;
  }
}
@media (max-width: 500px) {
  section#intro6 > div {
    padding: 7rem 1rem 7rem 1rem !important;
    background-size: cover !important;
  }
  .header .header-container .brand img {
    width: 140px !important;
  }
}
@media (max-width: 600px) {
  .header .header-container .brand img {
    margin-top: 3rem !important;
  }
}

.header .header__toggle {
  width: 42px !important;
  height: 42px !important;
  background: #fff !important;
  color: #011c3a !important;
  /* border: 2px solid #011c3a; */
}
.footer1__copyright {
  text-align: center;
}

form {
  display: block;
  margin-top: 0em;
}
textarea,
input {
  background: #f3f4f6;
  border-radius: 4px;
  border: none;
  box-sizing: border-box;
  color: #111;
  width: 100%;
  font-size: 15px;
  font-size: 1.1em;
  margin: 2px 0 0.4em 0;
  font-size: 1.2em;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0.5rem;
  width: 100%;
  text-overflow: ellipsis;
  text-align: center;
}
fieldset {
  border: none !important;
}
.flex.items-center.relative.form {
  width: 100% !important;
  display: block;
  padding: 1rem;
  background: #004cd5;
  color: white !important;
  font-size: 1.2rem;
}

.submit-form {
  color: #004cd5 !important;
  font-size: 1.2rem !important;
}
a.logo-link {
  display: block;
  margin: 0px;
  padding: 0px;
}

.logo-link img {
  float: left;
  display: block;
}

.header .header-container .brand img {
  width: 165px;
  margin-top: 3px;
}
.footer1__about img {
  width: 225px;
  max-width: 90% !important;
}
.italictext {
  font-style: italic;
}

.landing p {
  color: black;
}
p.font-bold,
p.font-normal {
  font-size: 1.3rem;
}
.text-center.team-icons svg {
  width: 20px;
  height: 20px;
  background-color: #f3f5fe;
  border-radius: 3px;
  margin-right: 7px;
  margin-left: 7px;
  padding: 11px;
  transition: 0.3s;
}
.text-center.team-icons svg:hover {
  background-color: #011c3a !important;
  color: white !important;
}
section#intro6 {
  padding-bottom: 0px !important;
}
h1 {
  /* font-style: italic; */
}
.emboldentext {
  font-weight: bold;
}
blockquote.twitter-tweet {
  background: #031c3a;
  color: white !important;
  padding: 2rem;
}
.twitter-tweet p,
.twitter-tweet a {
  color: white !important;
}
textarea {
  text-align: left;
}
.footer1__links ul {
  list-style-type: none;
}
